import React from 'react';
import { graphql } from 'gatsby';
import ArticlesList from 'components/ArticlesList';
import SEO from 'components/Seo';

const Blog = ({ data, pageContext }) => (
  <>
    <SEO
      title={data?.datoCmsBlogPage?.seo?.title}
      description={data?.datoCmsBlogPage?.seo?.description}
    />
    <ArticlesList data={data} pageContext={pageContext} />
  </>
);

export const query = graphql`
  query {
    datoCmsBlogPage {
      title
      description
      seo {
        title
        description
      }
    }

    allDatoCmsTag {
      edges {
        node {
          id
          slug
          name
        }
      }
    }

    allDatoCmsArticle {
      edges {
        node {
          id
          slug
          title
          kicker
          meta {
            firstPublishedAt
          }
          cover {
            url
            fluid(maxHeight: 200) {
              ...GatsbyDatoCmsFluid
            }
          }
          tags {
            id
            name
          }
        }
      }
    }
  }
`;

export default Blog;
