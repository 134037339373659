import React from 'react';
import { Container, Subtitle, Title, CardsList } from 'ui';
import Layout from 'components/Layout';
import { Box } from '@tymate/margaret';
import ArticleCard from 'components/ArticleCard';
import { Tags, Tag } from 'components/Tags';
import { Helmet } from 'react-helmet';

const ArticlesList = ({ data, isFaceted, pageContext }) => (
  <Layout>
    {isFaceted && (
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
    )}

    <Container variant="main">
      <Title>{data?.datoCmsBlogPage?.title}</Title>
      <Subtitle>{data?.datoCmsBlogPage?.description}</Subtitle>

      <Tags marginTop={2} as={Box}>
        {(data?.allDatoCmsTag?.edges || []).map(({ node }) => (
          <Tag
            key={node?.id}
            variant="pill"
            to={
              pageContext?.slug === node?.slug
                ? `/blog`
                : `/blog/categories/${node?.slug}`
            }
            isActive={pageContext?.slug === node?.slug}
          >
            {node?.name}
          </Tag>
        ))}
      </Tags>

      <CardsList variant="articles">
        {(data?.allDatoCmsArticle?.edges || []).map(({ node }) => (
          <ArticleCard article={node} key={node.id} />
        ))}
      </CardsList>
    </Container>
  </Layout>
);

export default ArticlesList;
